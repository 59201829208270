export const projects = [
  {
    name: 'Moment NFT',
    year: '2021',
    type: 'NFT Social Network',
    link: 'https://momentnft.org',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/moment.jpg'),
    techs: [ 'NestJS', 'VueJS', 'Vuetify', 'NFT', 'DeFi' ],
    desc: 'Description',
  },
  {
    name: 'Ridny Krai',
    year: '2021',
    type: 'Online charity',
    link: 'https://ua.ridnykrai.com',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/ridnykrai.jpg'),
    techs: [ 'Express', 'VueJS', 'Vuetify' ],
    desc: 'Description',
  },
  {
    name: 'Klik',
    year: '2020',
    type: 'Online charity',
    link: 'https://app.klik.shtl.cc/',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/klik.jpg'),
    techs: [ 'Laravel', 'VueJS' ],
    desc: 'Description',
  },
  {
    name: 'Smart Fertilizer',
    year: '2020',
    type: 'Smart',
    link: 'https://smart-fertilizer.com',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/smart-fertilizer.jpg'),
    techs: [ 'Express', 'VueJS', 'Vuetify', 'Kubernetes', 'ReactNative', 'Laravel' ],
    desc: 'Description',
  },
  {
    name: 'Alanica Dates',
    year: '2020',
    type: 'Online charity',
    link: 'https://alanicadates.com/',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/alanica.jpg'),
    techs: [ 'Express', 'VueJS', 'Vuetify', 'ReactNative', 'Machine Learning' ],
    desc: 'Description',
  },
  {
    name: 'Realic',
    year: '2021',
    type: 'Mobile NFT Marketplace',
    link: '#',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/realic.jpg'),
    techs: [ 'ReactNative', 'NFT' ],
    desc: 'Description',
  },
  {
    name: 'Ad2Life',
    year: '2020',
    type: 'Online charity',
    link: 'https://app.ad2.life',
    link_label: 'ua.ridnykrai.com',
    img: require('@/assets/projects/ad2life.jpg'),
    techs: [ 'Express', 'VueJS', 'Vuetify', 'ReactNative' ],
    desc: 'Description',
  },
]