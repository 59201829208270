<template>
  <div class="projects-sidebar">
    
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.projects-sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #22033E;
  width: 100%;
  height: 300px;
  border-radius: 30px 30px 0 0;
}

</style>