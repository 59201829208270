<template>
  <svg @click="goTo(0)" width="1106" height="290" viewBox="0 0 1106 290" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M236.751 47.8511L293.588 145.647C296.573 150.782 299.017 156.02 300.949 161.312H260.977L205.464 65.7952C182.289 25.9193 124.35 25.9193 101.175 65.7952L45.6615 161.312H5.68946C7.62201 156.02 10.0661 150.782 13.0506 145.647L69.8882 47.851C106.969 -15.9504 199.67 -15.9503 236.751 47.8511ZM2.97365 170.032C2.34731 172.45 1.82278 174.874 1.39737 177.298H38.4587C39.161 174.861 40.0309 172.435 41.0754 170.032H2.97365ZM265.564 170.032C266.608 172.435 267.478 174.861 268.18 177.298H305.242C304.816 174.874 304.292 172.45 303.665 170.032H265.564ZM270.285 188.924H306.534C306.649 191.355 306.669 193.779 306.596 196.191H270.392C270.507 193.785 270.474 191.359 270.285 188.924ZM0.105404 188.924H36.3538C36.1654 191.359 36.1323 193.785 36.2473 196.191H0.0432441C-0.0302355 193.779 -0.0104335 191.355 0.105404 188.924ZM1.36374 209.27C1.78469 211.716 2.30089 214.14 2.90924 216.537H40.9058C39.9066 214.175 39.0567 211.747 38.365 209.27H1.36374ZM268.274 209.27C267.582 211.747 266.732 214.175 265.733 216.537H303.73C304.338 214.14 304.854 211.716 305.275 209.27H268.274ZM257.026 231.069H298.776C297.708 233.539 296.537 235.964 295.269 238.336H250.086C252.606 236.104 254.925 233.67 257.026 231.069ZM7.86336 231.069H49.6135C51.7139 233.67 54.0335 236.104 56.5526 238.336H11.37C10.1015 235.964 8.9313 233.539 7.86336 231.069ZM22.0707 254.322C24.1515 256.849 26.363 259.275 28.698 261.588H277.941C280.276 259.275 282.488 256.849 284.568 254.322H22.0707ZM55.9517 280.48H250.687C238.571 286.042 224.914 289.2 210.157 289.2H96.482C81.7246 289.2 68.0684 286.042 55.9517 280.48Z" fill="url(#paint0_radial)"/>
<path d="M414.789 81.8713C414.789 86.5519 416.525 89.949 419.997 92.0628C423.47 94.6295 430.868 97.2717 442.192 99.9895C454.12 102.858 462.802 106.784 468.237 111.766C473.823 116.749 476.617 123.921 476.617 133.282C476.617 143.096 472.918 151.022 465.519 157.062C458.272 163.101 448.76 166.121 436.983 166.121C419.922 166.121 404.522 160.081 390.782 148.003L402.559 133.961C414.034 143.775 425.659 148.682 437.436 148.682C443.325 148.682 448.081 147.399 451.704 144.832C455.328 142.114 457.14 138.641 457.14 134.414C457.14 130.186 455.479 126.865 452.157 124.449C448.987 122.033 443.249 119.844 434.945 117.881C420.601 114.559 410.636 110.634 405.05 106.104C398.558 101.122 395.312 93.4971 395.312 83.2302C395.312 73.2652 398.935 65.4895 406.182 59.9031C413.43 54.1657 422.715 51.297 434.039 51.297C449.288 51.297 462.349 55.751 473.219 64.6591L463.255 78.7007C459.933 75.681 455.479 73.2652 449.892 71.4534C444.306 69.4906 438.795 68.5092 433.36 68.5092C427.773 68.5092 423.244 69.7171 419.771 72.1329C416.449 74.3976 414.789 77.6438 414.789 81.8713Z" fill="white"/>
<path d="M499.814 164.762V54.0147H518.611V102.028H573.419V54.0147H592.216V164.762H573.419V119.466H518.611V164.762H499.814Z" fill="white"/>
<path d="M668.478 148.229C677.084 148.229 683.878 145.285 688.861 139.396C693.994 133.508 696.561 125.506 696.561 115.39V54.0147H715.358V116.069C715.358 131.772 711.055 144.001 702.449 152.759C693.843 161.516 682.519 165.894 668.478 165.894C654.436 165.894 643.112 161.516 634.506 152.759C625.9 144.001 621.597 131.772 621.597 116.069V54.0147H640.168V115.39C640.168 125.657 642.735 133.659 647.868 139.396C652.851 145.285 659.721 148.229 668.478 148.229Z" fill="white"/>
<path d="M786.709 71.0005V164.762H767.912V71.0005H734.393V54.0147H820.228V71.0005H786.709Z" fill="white"/>
<path d="M882.606 71.0005V164.762H863.808V71.0005H830.29V54.0147H916.124V71.0005H882.606Z" fill="white"/>
<path d="M936.604 164.762V54.0147H955.402V147.097H1005.91V164.762H936.604Z" fill="white"/>
<path d="M1104.19 54.0147V71.4534H1045.08V100.895H1098.07V117.428H1045.08V147.097H1106V164.762H1026.28V54.0147H1104.19Z" fill="white"/>
<path d="M399.615 210.274C399.615 212.614 400.483 214.313 402.219 215.37C403.955 216.653 407.655 217.974 413.316 219.333C419.28 220.768 423.621 222.73 426.339 225.222C429.132 227.713 430.529 231.299 430.529 235.979C430.529 240.886 428.679 244.85 424.98 247.869C421.356 250.889 416.6 252.399 410.712 252.399C402.181 252.399 394.481 249.379 387.611 243.34L393.5 236.319C399.237 241.226 405.05 243.679 410.938 243.679C413.883 243.679 416.261 243.038 418.072 241.754C419.884 240.396 420.79 238.659 420.79 236.545C420.79 234.432 419.96 232.771 418.299 231.563C416.714 230.355 413.845 229.26 409.693 228.279C402.521 226.618 397.539 224.655 394.745 222.391C391.499 219.899 389.876 216.087 389.876 210.954C389.876 205.971 391.688 202.083 395.312 199.29C398.935 196.421 403.578 194.987 409.24 194.987C416.865 194.987 423.395 197.214 428.83 201.668L423.848 208.689C422.187 207.179 419.96 205.971 417.167 205.065C414.373 204.084 411.618 203.593 408.9 203.593C406.107 203.593 403.842 204.197 402.106 205.405C400.445 206.537 399.615 208.16 399.615 210.274Z" fill="white"/>
<path d="M467.719 203.48C462.208 203.48 457.565 205.443 453.791 209.368C450.016 213.294 448.129 218.05 448.129 223.636C448.129 229.223 450.016 233.979 453.791 237.904C457.565 241.754 462.208 243.679 467.719 243.679C473.23 243.679 477.873 241.754 481.647 237.904C485.422 233.979 487.309 229.223 487.309 223.636C487.309 218.05 485.422 213.294 481.647 209.368C477.873 205.443 473.23 203.48 467.719 203.48ZM467.719 252.286C459.415 252.286 452.47 249.568 446.883 244.132C441.297 238.621 438.504 231.789 438.504 223.636C438.504 215.483 441.297 208.689 446.883 203.253C452.47 197.742 459.415 194.987 467.719 194.987C476.023 194.987 482.968 197.742 488.555 203.253C494.141 208.689 496.935 215.483 496.935 223.636C496.935 231.789 494.104 238.584 488.442 244.019C482.78 249.53 475.872 252.286 467.719 252.286Z" fill="white"/>
<path d="M518.139 205.065V220.239H542.711V228.845H518.139V251.719H508.74V196.346H545.656L545.542 205.065H518.139Z" fill="white"/>
<path d="M577.637 204.839V251.719H568.238V204.839H551.479V196.346H594.396V204.839H577.637Z" fill="white"/>
<path d="M621.962 236.206L634.305 196.346H642.684L655.027 236.206L668.956 196.346H679.034L659.67 251.719H650.385L638.834 213.785H638.155L626.491 251.719H617.206L597.842 196.346H607.92L621.962 236.206Z" fill="white"/>
<path d="M695.582 239.15L690.033 251.719H680.068L704.527 196.346H714.492L738.839 251.719H728.874L723.325 239.15H695.582ZM719.588 230.431L709.51 207.67L699.432 230.431H719.588Z" fill="white"/>
<path d="M778.823 231.563L793.318 251.719H781.428L768.179 233.035H755.949V251.719H746.55V196.346H767.16C775.539 196.346 781.541 197.78 785.165 200.649C788.864 203.442 790.713 208.009 790.713 214.351C790.713 223.485 786.75 229.223 778.823 231.563ZM767.499 224.655C772.708 224.655 776.294 223.863 778.257 222.277C780.22 220.692 781.201 218.012 781.201 214.237C781.201 210.614 780.22 208.16 778.257 206.877C776.294 205.518 772.784 204.839 767.726 204.839H755.949V224.655H767.499Z" fill="white"/>
<path d="M843.214 196.346V205.065H813.659V219.786H840.156V228.053H813.659V242.887H844.12V251.719H804.26V196.346H843.214Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(154.046 31.9718) rotate(90.2359) scale(256.333 316.21)">
<stop stop-color="#FF2A75"/>
<stop offset="1" stop-color="#22033E"/>
</radialGradient>
</defs>
</svg>

</template>

<script>
export default {
  methods: {
    goTo(n) {

      const scroll_elem = document.querySelector('#scroll_elem');

      const scroll = (scroll_elem.scrollHeight - document.body.clientHeight) * n;

      scroll_elem.scroll(0, scroll);
      this.$root.show_menu = false;
    },
  }
}
</script>

<style lang="scss" scoped>

svg {
  max-height: 40px;
  width: auto;
  cursor: pointer;
  // background: rgba($color: #000000, $alpha: 0.3);
  // backdrop-filter: blur(10px);
  // padding: 10px 15px;
  // border-radius: 5px;
}

</style>